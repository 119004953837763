import React from 'react';
import { notificationsMachine } from './Notifications';
import { StyledHeader, StyledLogo } from './App';
import { interpret } from 'xstate';
var notificationsService = interpret(notificationsMachine).start();
export var notificationsActor = {
  toJSON: function toJSON() {
    return {
      id: 'notifications'
    };
  },
  id: 'notifications',
  send: notificationsService.send.bind(notificationsService),
  subscribe: notificationsService.subscribe.bind(notificationsService),
  notify: function notify(message) {
    return notificationsService.send({
      type: 'NOTIFICATIONS.QUEUE',
      data: typeof message === 'string' ? {
        message: message,
        severity: 'info'
      } : message
    });
  }
};
export function Header() {
  return React.createElement(StyledHeader, null, React.createElement(StyledLogo, null));
}