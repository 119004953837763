var __makeTemplateObject = this && this.__makeTemplateObject || function (cooked, raw) {
  if (Object.defineProperty) {
    Object.defineProperty(cooked, "raw", {
      value: raw
    });
  } else {
    cooked.raw = raw;
  }

  return cooked;
};

import styled from 'styled-components';
export var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  background: transparent;\n  color: white;\n  height: 2rem;\n  border-radius: 0.25rem;\n  border: none;\n  font-weight: bold;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n  flex-shrink: 0;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n\n  &:hover {\n    background: white;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &[data-variant='primary'] {\n    background: white;\n    color: #111;\n    border-radius: 2px;\n    border: none;\n  }\n\n  &[data-variant='secondary'] {\n    background: #656565;\n    border-radius: 2px;\n    border: none;\n  }\n\n  &[data-variant='link'] {\n    background: transparent;\n    color: var(--color-link);\n    letter-spacing: 0;\n    height: auto;\n    padding: 0;\n    margin-left: 0.5rem;\n  }\n\n  &[data-size='full'] {\n    width: 100%;\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"], ["\n  appearance: none;\n  background: transparent;\n  color: white;\n  height: 2rem;\n  border-radius: 0.25rem;\n  border: none;\n  font-weight: bold;\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n  flex-shrink: 0;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n\n  &:hover {\n    background: white;\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &[data-variant='primary'] {\n    background: white;\n    color: #111;\n    border-radius: 2px;\n    border: none;\n  }\n\n  &[data-variant='secondary'] {\n    background: #656565;\n    border-radius: 2px;\n    border: none;\n  }\n\n  &[data-variant='link'] {\n    background: transparent;\n    color: var(--color-link);\n    letter-spacing: 0;\n    height: auto;\n    padding: 0;\n    margin-left: 0.5rem;\n  }\n\n  &[data-size='full'] {\n    width: 100%;\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n"])));
var templateObject_1;